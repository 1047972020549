import React from 'react';
import {
  Table,
  useTable,
  styled,
  useTheme,
  Box,
  IconButton,
  DeleteIcon,
  MRT_Row,
} from '@cherre-frontend/ui';
import { useMappingColumnDefinitions } from 'src/products/models-library/hooks/useColumnDefinitions';
import { Control } from 'react-hook-form';
import { MappingFieldsTableValues } from '../schema';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export type SchemaTableProps = {
  control: Control<MappingFieldsTableValues>;
  tableCreated: boolean;
  targetTableState?: MappingFieldsTableValues['fields'];
  fieldNameOptions: Array<{ label: string; value: string }>;
  setTargetTableState?: (tableData: MappingFieldsTableValues['fields']) => void;
};

export const SchemaTable: React.FC<SchemaTableProps> = ({
  control,
  tableCreated,
  targetTableState,
  fieldNameOptions,
  setTargetTableState,
}) => {
  const theme = useTheme();

  const handleAddRowClick = () => {
    setTargetTableState?.([
      ...(targetTableState ?? []),
      {
        fieldName: '',
        referenceColumn: '',
        lookupTable: '',
        lookupColumn: '',
        description: '',
      },
    ]);
  };
  const handleDeleteRowClick =
    (row: MRT_Row<MappingFieldsTableValues['fields'][number]>) =>
    (_event: React.MouseEvent) => {
      setTargetTableState?.(
        (targetTableState ?? []).filter((_, index) => index !== row.index)
      );
    };
  const columns = useMappingColumnDefinitions({
    control,
    addTableRow: handleAddRowClick,
    fieldNameOptions,
    disabled: tableCreated,
  });
  const table = useTable({
    data: targetTableState,
    layoutMode: 'grid',
    skeletonRowCount: 5,
    columns,
    enableSorting: false,
    enableColumnResizing: true,
    enableRowSelection: false,
    enableRowActions: !tableCreated,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 30,
      },
    },
    renderRowActions: ({ row }) => (
      <IconButton onClick={handleDeleteRowClick(row)} color='error'>
        <DeleteIcon />
      </IconButton>
    ),
    enableBottomToolbar: true,
    enablePagination: tableCreated,
    enableStickyHeader: true,
    enableStickyFooter: !tableCreated,
    enableRowVirtualization: true,
    enableColumnVirtualization: true,
    muiTableProps: {
      sx: {
        height: '100%',
      },
    },
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        borderRadius: '16px 16px 16px 16px',
        maxHeight: '800px',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        border: `1px ${theme.palette.grey[300]} solid`,
        borderRadius: '16px',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: 'white',
        border: '0px',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '&:hover td': {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    manualPagination: false,
    manualSorting: false,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      columnPinning: { right: ['mrt-row-actions'] },
    },
    getRowId: (row, index) => /* row.name ??  */ index.toString(),
    renderEmptyRowsFallback: () =>
      tableCreated ? <Box>No columns to map</Box> : undefined,

    //TODO: @gaspardetienne97 remove memoMode to the table if it's not needed
    // memoMode: 'cells', // 'cells' | 'rows' | 'table-body'
  });

  return (
    <Container sx={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
      <Table table={table} />
    </Container>
  );
};

SchemaTable.displayName = 'SchemaTable';
export default SchemaTable;

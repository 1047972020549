import {
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useLibraryModelsListQuery } from './__generated__/useLibraryModelsListQuery.graphql';

const $libraryModelsList = graphQLSelector({
  environment: 'dom',
  query: graphql`
    query useLibraryModelsListQuery {
      targetTableDefinitionsPackages {
        uid
        name
        displayName
        description
      }
    }
  ` as GraphQLReturn<useLibraryModelsListQuery>,
  mapResponse: (resp) => {
    return resp.targetTableDefinitionsPackages;
  },
});

export const useLibraryModelsList = () => {
  const modelsList = useCherreValue($libraryModelsList({}));

  return modelsList;
};

import { Box, Button, Typography, ArrowBackIcon } from '@cherre-frontend/ui';
import React from 'react';

interface HeaderProps {
  title: string;
  subtitle?: string;
  headerImage?: string;
  goBackHref?: string;
  goBackText?: string;
}

const Header: React.FC<HeaderProps> = ({
  headerImage,
  title,
  subtitle,
  goBackHref,
  goBackText,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      paddingTop='10px'
      justifyContent={'space-between'}
      gap='20px'
    >
      <Box display='flex' flexDirection='row' gap='20px'>
        {headerImage && <img height='100%' src={headerImage} />}
        <Box>
          {title && <Typography variant='h3'>{title}</Typography>}
          {subtitle && <Typography variant='body1'>{subtitle}</Typography>}
        </Box>
      </Box>
      {goBackHref && (
        <Button
          variant='text'
          size='small'
          startIcon={<ArrowBackIcon />}
          href={goBackHref}
        >
          {goBackText}
        </Button>
      )}
    </Box>
  );
};

Header.displayName = 'ModelsLibrabryPage/Header';
export default Header;

import {
  GraphQLReturn,
  useMutationAsync,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useLibraryModelCreateMutation } from './__generated__/useLibraryModelCreateMutation.graphql';

export const useLibraryModelCreate = () => {
  return useMutationAsync(
    graphql`
      mutation useLibraryModelCreateMutation(
        $targetTableDefinitionsPackageName: String!
        $targetTableDefinitionNames: [String!]
        $description: String
        $displayName: String!
      ) {
        createTargetTableDefinitionsPackage(
          targetTableDefinitionsPackageName: $targetTableDefinitionsPackageName
          targetTableDefinitionNames: $targetTableDefinitionNames
          description: $description
          displayName: $displayName
        ) {
          name
          uid
          targetTableDefinitions {
            name
          }
          owner {
            name
          }
        }
      }
    ` as GraphQLReturn<useLibraryModelCreateMutation>
  );
};

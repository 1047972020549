/**
 * @generated SignedSource<<a24601996648768948b82596cbc500d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useLibraryModelsListQuery$variables = {};
export type useLibraryModelsListQuery$data = {
  readonly targetTableDefinitionsPackages: ReadonlyArray<{
    readonly description: string | null;
    readonly displayName: string;
    readonly name: string;
    readonly uid: string;
  }>;
};
export type useLibraryModelsListQuery = {
  response: useLibraryModelsListQuery$data;
  variables: useLibraryModelsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TargetTableDefinitionsPackage",
    "kind": "LinkedField",
    "name": "targetTableDefinitionsPackages",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLibraryModelsListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useLibraryModelsListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "99a6770bf08d15ae3427c8142e24222f",
    "id": null,
    "metadata": {},
    "name": "useLibraryModelsListQuery",
    "operationKind": "query",
    "text": "query useLibraryModelsListQuery {\n  targetTableDefinitionsPackages {\n    uid\n    name\n    displayName\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "45ecc76dd43214d5ec7ae100dcf8c6fe";

export default node;

import React from 'react';
import {
  Box,
  Paper,
  Typography,
  MuiAutocomplete as Autocomplete,
  TextField,
} from '@cherre-frontend/ui';

export interface OrganizationOption {
  label: string;
  value: string;
}
type ModelLibrarySectionProps = {
  title: string;
  description: string;
  autoCompleteOptions: string[];
  selectedValues: string[];
  onAutoCompleteChange: (value: string[]) => void;
};
export const ModelLibrarySection: React.FC<ModelLibrarySectionProps> = ({
  title,
  description,
  autoCompleteOptions,
  onAutoCompleteChange,
  selectedValues,
}) => {
  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box display='flex' flexDirection='column' gap='8px'>
        <Box display='flex' flexDirection='column' gap='4px'>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='body1'>{description}</Typography>
        </Box>

        <Autocomplete
          value={selectedValues}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              color='primary'
              placeholder='Select'
              size='small'
            />
          )}
          onChange={(_, value) => {
            value && onAutoCompleteChange(value);
          }}
          options={autoCompleteOptions}
        />
      </Box>
    </Paper>
  );
};

ModelLibrarySection.displayName = 'ModelLibrarySection';
export default ModelLibrarySection;

import {
  GraphQLReturn,
  useMutationAsync,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useLibraryModelUpdateMutation } from './__generated__/useLibraryModelUpdateMutation.graphql';

export const useLibraryModelUpdate = () => {
  return useMutationAsync(
    graphql`
      mutation useLibraryModelUpdateMutation(
        $targetTableDefinitionsPackageUID: String!
        $targetTableDefinitionNames: [String!]
        $displayName: String!
      ) {
        updateTargetTableDefinitionsPackage(
          targetTableDefinitionsPackageUID: $targetTableDefinitionsPackageUID
          targetTableDefinitionNames: $targetTableDefinitionNames
          displayName: $displayName
        ) {
          name
          uid
          targetTableDefinitions {
            name
          }
          owner {
            name
          }
        }
      }
    ` as GraphQLReturn<useLibraryModelUpdateMutation>
  );
};

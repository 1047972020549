import {
  MRT_ColumnDef,
  AddCircleIcon,
  Button,
  ControlledInput,
  ControlledSelect,
  MuiAutocomplete as Autocomplete,
  TextField,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  MappingFieldsTableValues,
  TargetTableFormValues,
  TSA_VALUES,
  TsaFieldsTableValues,
} from '../schema';

type UseMappingColumnDefinitionsProps = {
  control: Control<MappingFieldsTableValues>;
  addTableRow: () => void;
  disabled?: boolean;
  fieldNameOptions: Array<{ label: string; value: string }>;
};
type UseTsaColumnDefinitionsProps = {
  control: Control<TsaFieldsTableValues>;
  addTableRow: () => void;
  disabled?: boolean;
  fieldNameOptions: Array<{ label: string; value: string }>;
};
type UseSchemaColumnDefinitionsProps = {
  control: Control<TargetTableFormValues>;
  addTableRow: () => void;
  disabled?: boolean;
};
export const useMappingColumnDefinitions = ({
  control,
  addTableRow,
  disabled,
  fieldNameOptions,
}: UseMappingColumnDefinitionsProps) => {
  // Create column definitions
  const columns: Array<
    MRT_ColumnDef<MappingFieldsTableValues['fields'][number]>
  > = useMemo(() => {
    return [
      {
        accessorKey: 'fieldName',
        header: 'Field Name',
        size: 150,
        Cell: ({ row }) => (
          <Controller
            render={({ ...props }) => (
              <Autocomplete
                options={fieldNameOptions.map((option) => option.value)}
                disabled={disabled}
                value={props.field.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select field name'
                    variant='standard'
                    fullWidth
                  />
                )}
                onChange={(e, data) => props.field.onChange(data)}
                fullWidth
                {...props}
              />
            )}
            name={`fields.${row.index}.fieldName`}
            control={control}
          />
        ),
        Footer: () => {
          return (
            !disabled && (
              <Button startIcon={<AddCircleIcon />} onClick={addTableRow}>
                Add mapped field
              </Button>
            )
          );
        },
      },
      {
        accessorKey: 'referenceColumn',
        header: 'Reference Column',
        size: 150,
        Cell: ({ row }) => (
          <ControlledSelect
            control={control}
            name={`fields.${row.index}.referenceColumn`}
            fullWidth
            disabled={disabled}
            color='primary'
            variant='standard'
            options={fieldNameOptions}
          />
        ),
      },
      {
        accessorKey: 'lookupTable',
        header: 'Lookup Table',
        size: 150,
        Cell: ({ row }) => (
          <ControlledSelect
            control={control}
            name={`fields.${row.index}.lookupTable`}
            fullWidth
            disabled={disabled}
            color='primary'
            variant='standard'
            options={fieldNameOptions}
          />
        ),
      },
      {
        accessorKey: 'description',
        header: 'Lookup Column',
        size: 150,
        Cell: ({ row }) => (
          <ControlledInput
            control={control}
            name={`fields.${row.index}.description`}
            fullWidth
            disabled={disabled}
            color='primary'
            variant='standard'
          />
        ),
      },
    ];
  }, [addTableRow, fieldNameOptions, disabled, control]);

  return columns;
};

export const useTsaColumnDefinitions = ({
  control,
  addTableRow,
  disabled,
  fieldNameOptions,
}: UseTsaColumnDefinitionsProps) => {
  // Create column definitions
  const columns: Array<MRT_ColumnDef<TsaFieldsTableValues['fields'][number]>> =
    useMemo(() => {
      return [
        {
          accessorKey: 'fieldName',
          header: 'Field Name',
          size: 150,
          Cell: ({ row }) => (
            <Controller
              render={({ ...props }) => (
                <Autocomplete
                  options={fieldNameOptions.map((option) => option.value)}
                  disabled={disabled}
                  value={props.field.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select field name'
                      variant='standard'
                      fullWidth
                    />
                  )}
                  onChange={(e, data) => props.field.onChange(data)}
                  fullWidth
                  {...props}
                />
              )}
              name={`fields.${row.index}.fieldName`}
              control={control}
            />
          ),
          Footer: () => {
            return (
              !disabled && (
                <Button startIcon={<AddCircleIcon />} onClick={addTableRow}>
                  Add TSA field
                </Button>
              )
            );
          },
        },
        {
          accessorKey: 'tsa',
          header: 'TSA',
          size: 150,
          Cell: ({ row }) => (
            <ControlledSelect
              control={control}
              name={`fields.${row.index}.tsa`}
              fullWidth
              disabled={disabled}
              color='primary'
              variant='standard'
              options={[
                { label: 'Enabled', value: TSA_VALUES.ENABLED },
                { label: 'Required', value: TSA_VALUES.REQUIRED },
              ]}
            />
          ),
        },
      ];
    }, [addTableRow, disabled, fieldNameOptions, control]);

  return columns;
};
export const useSchemaColumnDefinitions = ({
  control,
  addTableRow,
  disabled = false,
}: UseSchemaColumnDefinitionsProps) => {
  const autoCompleteOptions = useMemo(() => {
    return [
      { label: 'Text', value: 'text' },
      { label: 'Boolean', value: 'boolean' },
      { label: 'Date', value: 'date' },
      { label: 'Time', value: 'time' },
      { label: 'Numeric', value: 'numeric' },
    ];
  }, []);

  // Create column definitions
  const columns: Array<
    MRT_ColumnDef<TargetTableFormValues['tableData'][number]>
  > = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Target Field Name',
        size: 150,
        Cell: ({ row }) => (
          <ControlledInput
            control={control}
            name={`tableData.${row.index}.name`}
            fullWidth
            disabled={disabled}
            color='primary'
            placeholder='Enter field name'
            variant='standard'
          />
        ),
        Footer: () => {
          return (
            !disabled && (
              <Button startIcon={<AddCircleIcon />} onClick={addTableRow}>
                Add new target field
              </Button>
            )
          );
        },
      },
      {
        accessorKey: 'displayName',
        header: 'Display Name',
        size: 150,
        Cell: ({ row }) => (
          <ControlledInput
            control={control}
            name={`tableData.${row.index}.displayName`}
            fullWidth
            disabled={disabled}
            color='primary'
            placeholder='Enter display name'
            variant='standard'
          />
        ),
      },
      {
        accessorKey: 'type',
        header: 'Data Type',
        size: 150,
        Cell: ({ row }) => (
          <ControlledSelect
            control={control}
            name={`tableData.${row.index}.type`}
            fullWidth
            disabled={disabled}
            color='primary'
            variant='standard'
            options={autoCompleteOptions}
          />
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description (Optional)',
        size: 150,
        Cell: ({ row }) => (
          <ControlledInput
            control={control}
            name={`tableData.${row.index}.description`}
            fullWidth
            disabled={disabled}
            color='primary'
            placeholder='Enter description'
            variant='standard'
          />
        ),
      },
    ];
  }, [addTableRow, autoCompleteOptions, disabled, control]);

  return columns;
};

import { z } from 'zod';

export enum TSA_VALUES {
  REQUIRED = 'required',
  ENABLED = 'enabled',
  DISABLED = '',
}

export enum TabValue {
  TableSchema,
  MappingConfig,
  ProductConfig,
}

// TSA Schema
const tsaSchema = z.object({
  required: z.boolean(),
  enabled: z.boolean(),
});

// Mapping fields schema
const mappingFieldsSchema = z.object({
  name: z.string(),
  targetName: z.string(),
  displayName: z.string(),
  required: z.boolean(),
  tsa: tsaSchema.nullable(),
});

// DSP schema
const dspSchema = z.object({
  required: z.boolean().default(false),
  submissionType: z.string().nullable().optional(),
  mappingFields: z.array(mappingFieldsSchema).nullable(),
  tsa: tsaSchema.nullable(),
});

// Table row schema
const tableRowSchema = z.object({
  name: z.string(),
  displayName: z.string().nullable(),
  type: z.string(),
  description: z.string().nullable(),
  dsp: dspSchema.nullable(),
});

// Target table form schema
export const targetTableFormSchema = z
  .object({
    tableName: z.string().min(1, { message: 'Table name is required' }),
    description: z.string().nullable(),
    dsp: z.boolean().default(false),
    submissionType: z.string().nullable().optional(),
    orgSlug: z.string().nullable(),
    tableData: z
      .array(tableRowSchema)
      .min(1, { message: 'At least one field row is required' }),
  })
  .refine(
    (data) => {
      if (data.dsp && !data.submissionType) {
        return false;
      }
      return true;
    },
    {
      message: 'Submission type is required for DSP tables',
      path: ['submissionType'],
    }
  );

// Mapping fields table row schema
const mappingFieldsRowSchema = z.object({
  fieldName: z.string().nullable(),
  referenceColumn: z.string().nullable(),
  lookupTable: z.string().nullable(),
  lookupColumn: z.string().nullable(),
  description: z.string().nullable(),
});

// Mapping fields table schema
export const mappingFieldsTableSchema = z.object({
  fields: z.array(mappingFieldsRowSchema),
});

// TSA fields row schema
const tsaFieldsRowSchema = z.object({
  fieldName: z.string().nullable(),
  tsa: z.nativeEnum(TSA_VALUES).nullable(),
});

// TSA fields table schema
export const tsaFieldsTableSchema = z.object({
  requiredFields: z.array(z.string()).optional(),
  fields: z.array(tsaFieldsRowSchema),
});

// Type exports
export type TargetTableFormValues = z.infer<typeof targetTableFormSchema>;
export type MappingFieldsTableValues = z.infer<typeof mappingFieldsTableSchema>;
export type TsaFieldsTableValues = z.infer<typeof tsaFieldsTableSchema>;

/**
 * @generated SignedSource<<773c8887f2e771ed80110cbc6a782dd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TargetTableDspDefinitionInput = {
  orgSlug?: string | null;
  submissionType?: string | null;
};
export type ColumnInput = {
  description?: string | null;
  displayName?: string | null;
  dsp?: DspColumnInput | null;
  name: string;
  type: string;
};
export type DspColumnInput = {
  defaultValue?: string | null;
  mappingFields?: ReadonlyArray<mappingFieldsInput> | null;
  required: boolean;
  tsa?: tsaFieldsInput | null;
};
export type mappingFieldsInput = {
  descriptionFieldName?: string | null;
  displayName: string;
  name: string;
  required: boolean;
  targetName: string;
  tsa?: tsaFieldsInput | null;
};
export type tsaFieldsInput = {
  enabled: boolean;
  required: boolean;
};
export type useModelTableCreateMutation$variables = {
  columns: ReadonlyArray<ColumnInput>;
  description?: string | null;
  displayName: string;
  dsp?: TargetTableDspDefinitionInput | null;
  targetTableDefinitionName: string;
};
export type useModelTableCreateMutation$data = {
  readonly createTargetTableDefinition: {
    readonly columns: ReadonlyArray<{
      readonly description: string | null;
      readonly displayName: string | null;
      readonly dsp: {
        readonly mappingFields: ReadonlyArray<{
          readonly displayName: string;
          readonly name: string;
          readonly required: boolean;
          readonly targetName: string;
          readonly tsa: {
            readonly enabled: boolean;
            readonly required: boolean;
          } | null;
        }> | null;
        readonly required: boolean;
        readonly tsa: {
          readonly enabled: boolean;
          readonly required: boolean;
        } | null;
      } | null;
      readonly name: string;
      readonly type: string;
    }>;
    readonly description: string | null;
    readonly displayName: string;
    readonly dsp: {
      readonly orgSlug: string | null;
      readonly submissionType: string | null;
    } | null;
    readonly name: string;
    readonly owner: {
      readonly name: string;
    };
    readonly uid: string;
  };
};
export type useModelTableCreateMutation = {
  response: useModelTableCreateMutation$data;
  variables: useModelTableCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dsp"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetTableDefinitionName"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "tsaFields",
  "kind": "LinkedField",
  "name": "tsa",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "columns",
        "variableName": "columns"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "displayName",
        "variableName": "displayName"
      },
      {
        "kind": "Variable",
        "name": "dsp",
        "variableName": "dsp"
      },
      {
        "kind": "Variable",
        "name": "targetTableDefinitionName",
        "variableName": "targetTableDefinitionName"
      }
    ],
    "concreteType": "TargetTableDefinition",
    "kind": "LinkedField",
    "name": "createTargetTableDefinition",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Owner",
        "kind": "LinkedField",
        "name": "owner",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "targetTableDspDefinition",
        "kind": "LinkedField",
        "name": "dsp",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submissionType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orgSlug",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Column",
        "kind": "LinkedField",
        "name": "columns",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DspColumn",
            "kind": "LinkedField",
            "name": "dsp",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "mappingFields",
                "kind": "LinkedField",
                "name": "mappingFields",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetName",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useModelTableCreateMutation",
    "selections": (v10/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useModelTableCreateMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "9bf7e03e870252eb7d09e2631a6cf84f",
    "id": null,
    "metadata": {},
    "name": "useModelTableCreateMutation",
    "operationKind": "mutation",
    "text": "mutation useModelTableCreateMutation(\n  $targetTableDefinitionName: String!\n  $displayName: String!\n  $description: String\n  $dsp: TargetTableDspDefinitionInput\n  $columns: [ColumnInput!]!\n) {\n  createTargetTableDefinition(targetTableDefinitionName: $targetTableDefinitionName, displayName: $displayName, description: $description, dsp: $dsp, columns: $columns) {\n    name\n    description\n    displayName\n    uid\n    owner {\n      name\n    }\n    dsp {\n      submissionType\n      orgSlug\n    }\n    columns {\n      name\n      displayName\n      type\n      description\n      dsp {\n        required\n        mappingFields {\n          name\n          targetName\n          displayName\n          required\n          tsa {\n            required\n            enabled\n          }\n        }\n        tsa {\n          required\n          enabled\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "465502d29f7b3fbe09a47f72e15e2f7d";

export default node;

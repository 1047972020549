/**
 * @generated SignedSource<<8bdc3c10cf4377b60df20ad139f9bdf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useLibraryModelDetailsQuery$variables = {
  uid?: string | null;
};
export type useLibraryModelDetailsQuery$data = {
  readonly targetTableDefinitionsPackage: {
    readonly description: string | null;
    readonly displayName: string;
    readonly name: string;
    readonly owner: {
      readonly name: string;
    };
    readonly targetTableDefinitions: ReadonlyArray<{
      readonly displayName: string;
      readonly dsp: {
        readonly orgSlug: string | null;
        readonly submissionType: string | null;
      } | null;
      readonly name: string;
      readonly uid: string;
    }> | null;
    readonly uid: string;
  };
};
export type useLibraryModelDetailsQuery = {
  response: useLibraryModelDetailsQuery$data;
  variables: useLibraryModelDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uid"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "uid"
      }
    ],
    "concreteType": "TargetTableDefinitionsPackage",
    "kind": "LinkedField",
    "name": "targetTableDefinitionsPackage",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Owner",
        "kind": "LinkedField",
        "name": "owner",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TargetTableDefinition",
        "kind": "LinkedField",
        "name": "targetTableDefinitions",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "targetTableDspDefinition",
            "kind": "LinkedField",
            "name": "dsp",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submissionType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orgSlug",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLibraryModelDetailsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLibraryModelDetailsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "04720017ef9283c8bf787f50ae0e74a3",
    "id": null,
    "metadata": {},
    "name": "useLibraryModelDetailsQuery",
    "operationKind": "query",
    "text": "query useLibraryModelDetailsQuery(\n  $uid: String\n) {\n  targetTableDefinitionsPackage(uid: $uid) {\n    uid\n    name\n    displayName\n    description\n    owner {\n      name\n    }\n    targetTableDefinitions {\n      uid\n      name\n      displayName\n      dsp {\n        submissionType\n        orgSlug\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "386141ad79b772b9a5d89d4bb0dd6d54";

export default node;

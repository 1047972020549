/**
 * @generated SignedSource<<58e022702828ef5d4a1be336249a1615>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useLibraryModelUpdateMutation$variables = {
  displayName: string;
  targetTableDefinitionNames?: ReadonlyArray<string> | null;
  targetTableDefinitionsPackageUID: string;
};
export type useLibraryModelUpdateMutation$data = {
  readonly updateTargetTableDefinitionsPackage: {
    readonly name: string;
    readonly owner: {
      readonly name: string;
    };
    readonly targetTableDefinitions: ReadonlyArray<{
      readonly name: string;
    }> | null;
    readonly uid: string;
  };
};
export type useLibraryModelUpdateMutation = {
  response: useLibraryModelUpdateMutation$data;
  variables: useLibraryModelUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetTableDefinitionNames"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetTableDefinitionsPackageUID"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "displayName",
        "variableName": "displayName"
      },
      {
        "kind": "Variable",
        "name": "targetTableDefinitionNames",
        "variableName": "targetTableDefinitionNames"
      },
      {
        "kind": "Variable",
        "name": "targetTableDefinitionsPackageUID",
        "variableName": "targetTableDefinitionsPackageUID"
      }
    ],
    "concreteType": "TargetTableDefinitionsPackage",
    "kind": "LinkedField",
    "name": "updateTargetTableDefinitionsPackage",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TargetTableDefinition",
        "kind": "LinkedField",
        "name": "targetTableDefinitions",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Owner",
        "kind": "LinkedField",
        "name": "owner",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLibraryModelUpdateMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useLibraryModelUpdateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5799e986b03701072118844ec97b34a3",
    "id": null,
    "metadata": {},
    "name": "useLibraryModelUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation useLibraryModelUpdateMutation(\n  $targetTableDefinitionsPackageUID: String!\n  $targetTableDefinitionNames: [String!]\n  $displayName: String!\n) {\n  updateTargetTableDefinitionsPackage(targetTableDefinitionsPackageUID: $targetTableDefinitionsPackageUID, targetTableDefinitionNames: $targetTableDefinitionNames, displayName: $displayName) {\n    name\n    uid\n    targetTableDefinitions {\n      name\n    }\n    owner {\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a6c5a191251934267e1cd796c173c6b";

export default node;

import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Breadcrumbs, Link, Typography, useTheme } from '@cherre-frontend/ui';
import {
  ModelsLibraryRoute,
  ModelDetailsRoute,
  ModelCreateRoute,
  ModelTableDetailsRoute,
  ModelTableCreateRoute,
} from '../routes';

interface LibraryBreadcrumbsProps {
  modelName?: string;
  tableName?: string;
}
export const LibraryBreadcrumbs: React.FC<LibraryBreadcrumbsProps> = ({
  modelName,
  tableName,
}) => {
  const theme = useTheme();
  const location = useLocation();

  const pathnames = location.pathname.split('/').filter((x) => x);
  //! This is the mapping of route paths to breadcrumb names
  //! Do not change the order of the routes as mathPath will match the first route that matches the path
  const breadcrumbRouteNameMap: [path: string, name: string][] = [
    [ModelsLibraryRoute.routeDescriptor.path, 'Data Models Library'],
    [ModelCreateRoute.routeDescriptor.path, 'Create Custom Data Model'],
    [ModelDetailsRoute.routeDescriptor.path, modelName ?? 'Custom Data Model'],
    [ModelTableCreateRoute.routeDescriptor.path, 'Create Target Table'],
    [ModelTableDetailsRoute.routeDescriptor.path, tableName ?? 'Target Table'],
  ];

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      sx={{ color: theme.palette.grey[400] }}
    >
      <Link underline='hover' color={theme.palette.grey[400]} href='/'>
        Home
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        const findMatchingRoute = (currentPath: string) => {
          return breadcrumbRouteNameMap.find((routeMapping) => {
            const path = routeMapping[0];
            const match = matchPath(currentPath, { path, exact: true });
            return match !== null;
          });
        };

        const crumbName = findMatchingRoute(to)?.[1];

        return last ? (
          <Typography key={to} sx={{ color: 'text.primary' }}>
            {crumbName}
          </Typography>
        ) : (
          crumbName && (
            <Link
              key={to}
              href={to}
              sx={{
                color: theme.palette.grey[400],
                cursor: 'pointer',
              }}
              underline='hover'
            >
              {crumbName}
            </Link>
          )
        );
      })}
    </Breadcrumbs>
  );
};

LibraryBreadcrumbs.displayName = 'LibraryBreadcrumbs';
export default LibraryBreadcrumbs;
